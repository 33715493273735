<script setup>
import { onBeforeUnmount, onBeforeMount, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import {useFirebase} from '@/composables/firebase'
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";

const body = document.getElementsByTagName("body")[0];
const router = useRouter()
const store = useStore();
const toast = useToast();
const {signIn, nationalAlerts} = useFirebase()

const email = ref('')
const password = ref('')

onMounted(()=>{
  nationalAlerts()
})

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});

const login = async () => {
  signIn(email.value, password.value)
    .then((resp) => {
      console.log(resp)
      router.push('/dashboard')
    })
    .catch(err => {
      console.log(err)
       toast.error('El correo o la contraseña son incorrectos')
    })
}

const nationalAlertsComputed = computed(()=>{
  return store.getters.getNationalAlerts
})

</script>

<template>
  <main class="mt-0 main-content main-bg-policop">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="logo p-4">
                <img src="../assets/img/main-logo.png" alt="" class="img-fluid mb-2">
              </div>
              <div class="card card-plain bg-white">
                <div class="pb-0 card-header text-center">
                  <h4 class="font-weight-bolder">Inicia sesión</h4>
                  <p class="mb-0">Ingresa tu correo y tu contraseña de administrador</p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="login">
                    <div class="mb-3">
                      <argon-input id="email" type="email" placeholder="Correo electrónico" name="email" size="lg"
                        v-model="email" />
                    </div>
                    <div class="mb-3">
                      <argon-input id="password" type="password" placeholder="Contraseña" name="password" size="lg"
                        v-model="password" />
                    </div>

                    <div class="text-center">
                      <argon-button class="mt-4" variant="gradient" color="primary" fullWidth size="lg">Sign
                        in</argon-button>
                    </div>
                  </form>
                </div>

              </div>

              <div class="mt-4 mb-4 text-center" text_danger>
                <h2 class="text-center text-white fs-5">¡Atención! Protegemos la integridad de POLICOP</h2>
                <small class="text-center text-white lh-sm d-block">Para evitar malentendidos, recuerda que POLICOP es un proyecto independiente desarrollado por Víctor Miranda. Ante cualquier duda, solicitud o colaboración, comunícate directamente con nosotros a través de <a class="text-white" href="mailto:victor.miranda374@gmail.com">victor.miranda374@gmail.com</a>. Gracias por tu apoyo para seguir mejorando la seguridad de todos.</small>
            </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(/img/bg_img.jpg);
                  background-size: cover;
                ">
                <span class="mask bg-gradient-primary opacity-6"></span>
                <h4 class="mt-5 text-white font-weight-bolder position-relative mb-5">
                  Alertas a nivel nacional
                </h4>
                <div class="row">
                  <div class="col-6" v-for="(item, index) in nationalAlertsComputed" :key="index" >
                    <mini-statistics-card
                      :title="item.title"
                      :value="item.value"
                      :icon="{
                        component: item.icon,
                        background: 'nothing',
                        shape: 'rounded-circle',
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>


